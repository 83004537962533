<template>
    <div>
        <div class="category-btns" :class="widget.style.roundedCorners ? 'border-radius-20' : 'border-radius-0'">
            <div class="be-widget-list-categories">
                <div v-if="!accountIsOpen" class="be-widget-list-categories__box">
                    <button type="button" id="remove" @click="btnCategoryRemove"
                            :class="{'category-btns__btn--active': activeCategory === 0, 'category-btns__btn': true}"
                            :style="activeCategory === 0 ? `background: ${widget.style.color}` : ''">
                        {{ $t('general.all') }}
                    </button>
                    <button type="button" v-for="item in categories" :key="item.id" :id="item.id"
                            @click="btnCategory(item.id)"
                            class="category-btns__btn">
                        {{ item.name }}
                    </button>
                </div>
            </div>
            <div v-if="!isAuth" class="login-register-container">
                <button @click="loginPopup = !loginPopup"
                        :class="`category-btns__btn--border ${ widget.style.roundedCorners ? 'border-radius-20' : 'border-radius-0'}`"
                        :style="{height: '44px', color: widget.style.color, border: '1px solid ' + widget.style.color, marginRight: '0'}">
                    {{ $t('general.log-in') }}
                </button>
            </div>
            <div v-if="isAuth" :style="'display:flex'" class="profile-logout-container">
                <button
                    :class="`category-btns__btn--active ${ widget.style.roundedCorners ? 'border-radius-20' : 'border-radius-0'}`"
                    :style="`height: 44px; border: 1px solid ${widget.style.color}; color: ${widget.style.color}!important;
                        display: flex; justify-content: center; align-items: center; margin-left:10px; margin-right: 0`"
                    :disabled="disabledLogout" @click="onCustomerLogout" id="logout-btn">
                    <svg width="20" height="20" :fill="widget.style.color" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 512 512">
                        <path
                            d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"/>
                    </svg>
                    <span class="btn-text"> {{ $t('general.logout') }}</span>
                </button>
                <button v-show="!accountIsOpen"
                        :class="`category-btns__btn--active ${ widget.style.roundedCorners ? 'border-radius-20' : 'border-radius-0'}`"
                        :style="`height: 44px; background: ${widget.style.color}; display: flex; justify-content: center; align-items: center;
                         margin-left:10px; margin-right: 0`"
                        @click="openAccount" id="account-btn">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.1322 9.05835C10.0488 9.05002 9.94883 9.05002 9.85716 9.05835C7.87383 8.99169 6.29883 7.36669 6.29883 5.36669C6.29883 3.32502 7.94883 1.66669 9.99883 1.66669C12.0405 1.66669 13.6988 3.32502 13.6988 5.36669C13.6905 7.36669 12.1155 8.99169 10.1322 9.05835Z"
                            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path
                            d="M5.96563 12.1333C3.94896 13.4833 3.94896 15.6833 5.96563 17.025C8.25729 18.5583 12.0156 18.5583 14.3073 17.025C16.324 15.675 16.324 13.475 14.3073 12.1333C12.024 10.6083 8.26562 10.6083 5.96563 12.1333Z"
                            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span class="btn-text"> {{ $t('general.account') }}</span>
                </button>
                <button v-show="accountIsOpen"
                        :class="`category-btns__btn--active ${ widget.style.roundedCorners ? 'border-radius-20' : 'border-radius-0'}`"
                        :style="`background: ${widget.style.color}; margin-left:10px; margin-right: 0;`"
                        @click="closeAccount">
                    {{ $t('general.back') }}
                </button>
            </div>
        </div>
        <v-overlay v-model="loginPopup">
            <div class="v-dialog v-dialog--active">
                <div class="login-popup v-card v-sheet theme--light">
                    <div class="login-popup__title">
                        <h3 v-if="showLoginForm" class="widget_title">{{ $t('general.log-in') }}</h3>
                        <h3 v-else class="widget_title">{{ $t('general.password-reset') }}</h3>
                        <div class="remove-from-cart" @click="loginPopup = false">&times;</div>
                    </div>
                    <div class="widget-row-form">
                        <custom-input v-model="customer_email" type="text" :placeholder="$t('general.email')"
                                      required
                                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                      style="padding: 8px 0 8px 14px;"
                                      :class="widget.style.roundedCorners ? '' : 'border-radius-0'"/>
                    </div>
                    <div v-if="showLoginForm" class="widget-row-form">
                        <custom-input v-model="customer_password" type="password"
                                      :placeholder="$t('general.password')"
                                      required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                      style="padding: 8px 0 8px 14px;"
                                      :class="widget.style.roundedCorners ? '' : 'border-radius-0'"/>
                    </div>
                    <div class="login-popup-links">
                        <a v-if="showLoginForm" class="login-popup-links__forgot" href="#"
                           @click.prevent="showLoginForm = false">
                            {{ $t('general.forgot-password') }}
                        </a>
                        <a v-else class="login-popup-links__forgot" href="#"
                           @click.prevent="showLoginForm = true">
                            {{ $t('general.back') }}
                        </a>
                    </div>
                    <button v-if="showLoginForm" type="button" :disabled="disabled"
                            :style="{ background: widget.style.color }" @click="onCustomerLogin"
                            class="login-popup-btn">
                        {{ $t('general.log-in') }}
                    </button>
                    <button v-else type="button" :disabled="resetLoader"
                            :style="{ background: !resetLoader ? widget.style.color : 'green' }"
                            @click="onPasswordForget"
                            class="login-popup-btn">
                        {{ $t('general.reset-password') }}
                    </button>
                </div>
            </div>
        </v-overlay>
        <div class="widget" :style="{ color: widget.style.color }"
             :class="show_mode === 2 ? 'show_mode_2' : 'show_mode_1'">
            <div v-if="String(shop_mode) === '2' && !accountIsOpen">
                <div v-if="isAuth && cart.length === 0" :class="widget.style.roundedCorners ? '' : 'border-radius-0'">
                    <button class="repeat-last-order-svg"
                            style="margin-right: 20px; margin-bottom: 10px"
                            @click="repeatLastOrder($event)">
                        <svg :fill="widget.style.color" height="30" width="30" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 384.967 384.967" xml:space="preserve">
                                    <path
                                        d="M72.18,192.479c6.641,0,12.03-5.39,12.03-12.03V84.206h199.595l-39.159,39.628c-4.728,4.752-4.728,12.439,0,17.191 c4.728,4.74,12.391,4.74,17.119,0l59.43-60.139c4.728-4.752,4.728-12.439,0-17.191l0,0l-59.43-60.139 c-4.728-4.74-12.391-4.74-17.119,0s-4.728,12.439,0,17.179l38.942,39.411H72.18c-6.641,0-12.03,5.39-12.03,12.03v108.273 C60.15,187.089,65.54,192.479,72.18,192.479z"/>
                            <path
                                d="M312.786,192.395c-6.641,0-12.03,5.39-12.03,12.03v96.615H100.728l39.508-40.061c4.728-4.752,4.728-12.463,0-17.215 c-4.728-4.752-12.391-4.752-17.119,0L64,303.723c-5.041,4.764-5.077,12.969,0,17.733l59.129,59.947 c4.728,4.752,12.391,4.752,17.119,0s4.728-12.463,0-17.215l-38.533-39.074h211.072c6.641,0,12.03-5.39,12.03-12.03V204.437 C324.817,197.784,319.427,192.395,312.786,192.395z"/>
                                </svg>
                    </button>
                    <button class="repeat-last-order-btn"
                            :style="`border: 1px solid ${widget.style.color};
                                color: ${widget.style.color} !important; margin-right: 20px; margin-bottom: 10px`"
                            @click="repeatLastOrder($event)">
                        {{ $t('general.repeat-last-order') }}
                    </button>
                </div>
                <form-data :widget="widget"
                           :cart="cart"
                           :form="form"
                           :shop_mode="shop_mode"
                           :blocked_btn="blocked_btn"
                           :vending-button="vendingButton"
                           :is-vending="isVending"
                           :vendingEnabled="vending_enabled"
                           @cancel-vending="cancelVending"
                           @change-date="onChangeDate"
                           class="shop_mode_2" @submit="$emit('submit', cart)"></form-data>
            </div>
            <div v-if="String(shop_mode) === '1' && !accountIsOpen">
                <div v-if="products.length < 1">{{ $t('general.there-are-no-products') }}</div>
                <div class="catalog-title-row">
                    <div style="display:flex;width: 80px">
                        <svg @click="btnRow()" width="30" height="30" viewBox="0 0 24 24"
                             style="cursor: pointer" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <path fill-rule="nonzero"
                                      d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"></path>
                                <path :fill="show_mode === 1 && !cartIsOpen ? widget.style.color : '#CCC'"
                                      d="M20,17.5 C20.8284,17.5 21.5,18.1716 21.5,19 C21.5,19.7796706 20.9050879,20.4204457 20.1444558,20.4931332 L20,20.5 L4,20.5 C3.17157,20.5 2.5,19.8284 2.5,19 C2.5,18.2203294 3.09488554,17.5795543 3.85553954,17.5068668 L4,17.5 L20,17.5 Z M20,10.5 C20.8284,10.5 21.5,11.1716 21.5,12 C21.5,12.8284 20.8284,13.5 20,13.5 L4,13.5 C3.17157,13.5 2.5,12.8284 2.5,12 C2.5,11.1716 3.17157,10.5 4,10.5 L20,10.5 Z M20,3.5 C20.8284,3.5 21.5,4.17157 21.5,5 C21.5,5.82843 20.8284,6.5 20,6.5 L4,6.5 C3.17157,6.5 2.5,5.82843 2.5,5 C2.5,4.17157 3.17157,3.5 4,3.5 L20,3.5 Z"></path>
                            </g>
                        </svg>
                        <svg @click="btnColumn()" width="30" height="30" viewBox="0 0 24 24"
                             style="cursor: pointer" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <path fill-rule="nonzero"
                                      d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"></path>
                                <path :fill="show_mode === 2 && !cartIsOpen ? widget.style.color : '#CCC'"
                                      d="M5.5,16.5 C6.60457,16.5 7.5,17.3954 7.5,18.5 C7.5,19.6046 6.60457,20.5 5.5,20.5 C4.39543,20.5 3.5,19.6046 3.5,18.5 C3.5,17.3954 4.39543,16.5 5.5,16.5 Z M12,16.5 C13.1046,16.5 14,17.3954 14,18.5 C14,19.6046 13.1046,20.5 12,20.5 C10.8954,20.5 10,19.6046 10,18.5 C10,17.3954 10.8954,16.5 12,16.5 Z M18.5,16.5 C19.6046,16.5 20.5,17.3954 20.5,18.5 C20.5,19.6046 19.6046,20.5 18.5,20.5 C17.3954,20.5 16.5,19.6046 16.5,18.5 C16.5,17.3954 17.3954,16.5 18.5,16.5 Z M5.5,10 C6.60457,10 7.5,10.8954 7.5,12 C7.5,13.1046 6.60457,14 5.5,14 C4.39543,14 3.5,13.1046 3.5,12 C3.5,10.8954 4.39543,10 5.5,10 Z M12,10 C13.1046,10 14,10.8954 14,12 C14,13.1046 13.1046,14 12,14 C10.8954,14 10,13.1046 10,12 C10,10.8954 10.8954,10 12,10 Z M18.5,10 C19.6046,10 20.5,10.8954 20.5,12 C20.5,13.1046 19.6046,14 18.5,14 C17.3954,14 16.5,13.1046 16.5,12 C16.5,10.8954 17.3954,10 18.5,10 Z M5.5,3.5 C6.60457,3.5 7.5,4.39543 7.5,5.5 C7.5,6.60457 6.60457,7.5 5.5,7.5 C4.39543,7.5 3.5,6.60457 3.5,5.5 C3.5,4.39543 4.39543,3.5 5.5,3.5 Z M12,3.5 C13.1046,3.5 14,4.39543 14,5.5 C14,6.60457 13.1046,7.5 12,7.5 C10.8954,7.5 10,6.60457 10,5.5 C10,4.39543 10.8954,3.5 12,3.5 Z M18.5,3.5 C19.6046,3.5 20.5,4.39543 20.5,5.5 C20.5,6.60457 19.6046,7.5 18.5,7.5 C17.3954,7.5 16.5,6.60457 16.5,5.5 C16.5,4.39543 17.3954,3.5 18.5,3.5 Z"></path>
                            </g>
                        </svg>
                    </div>
                    <div style="display: flex; justify-content: flex-start">
                        <div v-if="isAuth && cart.length === 0"
                             :class="widget.style.roundedCorners ? '' : 'border-radius-0'">
                            <button class="repeat-last-order-svg"
                                    :style="`margin-right: 20px`"
                                    @click="repeatLastOrder($event)">
                                <svg :fill="widget.style.color" height="30" width="30"
                                     xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 384.967 384.967" xml:space="preserve">
                                    <path
                                        d="M72.18,192.479c6.641,0,12.03-5.39,12.03-12.03V84.206h199.595l-39.159,39.628c-4.728,4.752-4.728,12.439,0,17.191 c4.728,4.74,12.391,4.74,17.119,0l59.43-60.139c4.728-4.752,4.728-12.439,0-17.191l0,0l-59.43-60.139 c-4.728-4.74-12.391-4.74-17.119,0s-4.728,12.439,0,17.179l38.942,39.411H72.18c-6.641,0-12.03,5.39-12.03,12.03v108.273 C60.15,187.089,65.54,192.479,72.18,192.479z"/>
                                    <path
                                        d="M312.786,192.395c-6.641,0-12.03,5.39-12.03,12.03v96.615H100.728l39.508-40.061c4.728-4.752,4.728-12.463,0-17.215 c-4.728-4.752-12.391-4.752-17.119,0L64,303.723c-5.041,4.764-5.077,12.969,0,17.733l59.129,59.947 c4.728,4.752,12.391,4.752,17.119,0s4.728-12.463,0-17.215l-38.533-39.074h211.072c6.641,0,12.03-5.39,12.03-12.03V204.437 C324.817,197.784,319.427,192.395,312.786,192.395z"/>
                                </svg>
                            </button>
                            <button class="repeat-last-order-btn"
                                    :style="`border: 1px solid ${widget.style.color};
                                color: ${widget.style.color} !important; margin-right: 20px`"
                                    @click="repeatLastOrder($event)">
                                {{ $t('general.repeat-last-order') }}
                            </button>
                        </div>
                        <div class="cart" @click="openCart">
                            <div class="cart__count" v-if="this.cart.length !== 0"
                                 :style="{ background: widget.style.color }">
                                {{ this.cart.length }}
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                 viewBox="0 0 122.9 107.5"
                                 style="margin-right: 3px;">
                                <path :fill="cart.length > 0 ? widget.style.color : '#AAA'"
                                      d="M3.9,7.9C1.8,7.9,0,6.1,0,3.9C0,1.8,1.8,0,3.9,0h10.2c0.1,0,0.3,0,0.4,0c3.6,0.1,6.8,0.8,9.5,2.5c3,1.9,5.2,4.8,6.4,9.1 c0,0.1,0,0.2,0.1,0.3l1,4H119c2.2,0,3.9,1.8,3.9,3.9c0,0.4-0.1,0.8-0.2,1.2l-10.2,41.1c-0.4,1.8-2,3-3.8,3v0H44.7 c1.4,5.2,2.8,8,4.7,9.3c2.3,1.5,6.3,1.6,13,1.5h0.1v0h45.2c2.2,0,3.9,1.8,3.9,3.9c0,2.2-1.8,3.9-3.9,3.9H62.5v0 c-8.3,0.1-13.4-0.1-17.5-2.8c-4.2-2.8-6.4-7.6-8.6-16.3l0,0L23,13.9c0-0.1,0-0.1-0.1-0.2c-0.6-2.2-1.6-3.7-3-4.5 c-1.4-0.9-3.3-1.3-5.5-1.3c-0.1,0-0.2,0-0.3,0H3.9L3.9,7.9z M96,88.3c5.3,0,9.6,4.3,9.6,9.6c0,5.3-4.3,9.6-9.6,9.6 c-5.3,0-9.6-4.3-9.6-9.6C86.4,92.6,90.7,88.3,96,88.3L96,88.3z M53.9,88.3c5.3,0,9.6,4.3,9.6,9.6c0,5.3-4.3,9.6-9.6,9.6 c-5.3,0-9.6-4.3-9.6-9.6C44.3,92.6,48.6,88.3,53.9,88.3L53.9,88.3z M33.7,23.7l8.9,33.5h63.1l8.3-33.5H33.7L33.7,23.7z"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="card-wrapper-row" v-if="show_mode === 1" v-show="!cartIsOpen">
                    <div v-for="item in products" :key="item.id">
                        <v-list-item
                            v-if="activeCategory !== 0 ? item.categories.map(val => val.id).includes(activeCategory) : true"
                            class="card-item mb-3 d-block">
                            <v-card class="d-flex"
                                    :class="show_mode === 1 ? 'card-product-row' : 'card-product-row-horizontal'">
                                <v-img :width="293" :height="185" :max-width="293" :max-height="185" cover
                                       :src="item.image"
                                       class="card-product-row__img"
                                       :class="widget.style.roundedCorners ? 'border-radius-20' : 'border-radius-0'"></v-img>
                                <div class="card-product-row__data">
                                    <v-card-text class="card-product__wrap-text" style="padding: 0">
                                        <v-card-title class="card-product-row__title text-bold"
                                                      style="padding: 0 0 10px;">
                                            {{ item.name }}
                                        </v-card-title>
                                        <v-card-subtitle class="card-product-row__description"
                                                         v-html="item.description"
                                                         :style="{ 'font-family': widget.style.fontFamily, 'padding': '10px 0 10px' }"
                                                         style="padding: 10px 0 10px;">
                                        </v-card-subtitle>
                                    </v-card-text>
                                </div>
                                <div class="card-product-row__card">
                                    <div
                                        :style="{ 'font-family': widget.style.fontFamily, color: widget.style.color }"
                                        class="order-block-row__price">
                                        &#8364; <span :id="'value_price_' + item.id">{{ item.price }}</span>
                                    </div>
                                    <v-card-actions
                                        :class="widget.style.roundedCorners ? 'card-actions' : 'border-radius-0'"
                                        style="padding-right: 0">
                                        <div class="order-block"
                                             :class="widget.style.roundedCorners ? '' : 'border-radius-0'">
                                            <div class="qty-block">
                                                <div class="qty-block__interface-row"
                                                     v-if="!hasVariation(item)">
                                                    <v-btn
                                                        :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                                                        small @click="btnMinus(item)"
                                                        class="order-block__btnMinus">
                                                        -
                                                    </v-btn>
                                                    <v-text-field solo small flat :id="'qty_' + item.id"
                                                                  class="qty-block__input order-block__text-field"
                                                                  :value="1"
                                                                  type="number" hide-details min="1"
                                                                  style="transform: translateX(2px)"
                                                                  @input="checkoutOrder(item)"
                                                                  :class="widget.style.roundedCorners ? '' : 'border-radius-0'"/>
                                                    <v-btn
                                                        :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                                                        :style="{ color: widget.style.color }" small
                                                        @click="btnPlus(item)"
                                                        class="order-block__btnPlus">
                                                        +
                                                        <span class="add-element"></span>
                                                    </v-btn>
                                                </div>
                                            </div>
                                            <v-btn @click="addCart(item)" title="Add to Basket"
                                                   class="btn_add_cart"
                                                   v-if="!hasVariation(item)"
                                                   small
                                                   :style="{ background: widget.style.color, 'font-family': widget.style.fontFamily, 'position': 'relative' }"
                                                   :class="hasVariation(item) ? 'without-qty-block' : ''">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                                     viewBox="0 0 122.9 107.5" style="margin-right: 3px;">
                                                    <path fill="currentColor"
                                                          d="M3.9,7.9C1.8,7.9,0,6.1,0,3.9C0,1.8,1.8,0,3.9,0h10.2c0.1,0,0.3,0,0.4,0c3.6,0.1,6.8,0.8,9.5,2.5c3,1.9,5.2,4.8,6.4,9.1 c0,0.1,0,0.2,0.1,0.3l1,4H119c2.2,0,3.9,1.8,3.9,3.9c0,0.4-0.1,0.8-0.2,1.2l-10.2,41.1c-0.4,1.8-2,3-3.8,3v0H44.7 c1.4,5.2,2.8,8,4.7,9.3c2.3,1.5,6.3,1.6,13,1.5h0.1v0h45.2c2.2,0,3.9,1.8,3.9,3.9c0,2.2-1.8,3.9-3.9,3.9H62.5v0 c-8.3,0.1-13.4-0.1-17.5-2.8c-4.2-2.8-6.4-7.6-8.6-16.3l0,0L23,13.9c0-0.1,0-0.1-0.1-0.2c-0.6-2.2-1.6-3.7-3-4.5 c-1.4-0.9-3.3-1.3-5.5-1.3c-0.1,0-0.2,0-0.3,0H3.9L3.9,7.9z M96,88.3c5.3,0,9.6,4.3,9.6,9.6c0,5.3-4.3,9.6-9.6,9.6 c-5.3,0-9.6-4.3-9.6-9.6C86.4,92.6,90.7,88.3,96,88.3L96,88.3z M53.9,88.3c5.3,0,9.6,4.3,9.6,9.6c0,5.3-4.3,9.6-9.6,9.6 c-5.3,0-9.6-4.3-9.6-9.6C44.3,92.6,48.6,88.3,53.9,88.3L53.9,88.3z M33.7,23.7l8.9,33.5h63.1l8.3-33.5H33.7L33.7,23.7z"/>
                                                </svg>
                                            </v-btn>
                                            <v-btn @click="addCart(item)" title="Add to Basket"
                                                   class="btn_add_cart"
                                                   small
                                                   v-else
                                                   :style="{ background: widget.style.color, 'font-family': widget.style.fontFamily, 'position': 'relative' }"
                                                   :class="hasVariation(item) ? 'without-qty-block' : ''">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                                     viewBox="0 0 122.9 107.5" style="margin-right: 3px;">
                                                    <path fill="currentColor"
                                                          d="M3.9,7.9C1.8,7.9,0,6.1,0,3.9C0,1.8,1.8,0,3.9,0h10.2c0.1,0,0.3,0,0.4,0c3.6,0.1,6.8,0.8,9.5,2.5c3,1.9,5.2,4.8,6.4,9.1 c0,0.1,0,0.2,0.1,0.3l1,4H119c2.2,0,3.9,1.8,3.9,3.9c0,0.4-0.1,0.8-0.2,1.2l-10.2,41.1c-0.4,1.8-2,3-3.8,3v0H44.7 c1.4,5.2,2.8,8,4.7,9.3c2.3,1.5,6.3,1.6,13,1.5h0.1v0h45.2c2.2,0,3.9,1.8,3.9,3.9c0,2.2-1.8,3.9-3.9,3.9H62.5v0 c-8.3,0.1-13.4-0.1-17.5-2.8c-4.2-2.8-6.4-7.6-8.6-16.3l0,0L23,13.9c0-0.1,0-0.1-0.1-0.2c-0.6-2.2-1.6-3.7-3-4.5 c-1.4-0.9-3.3-1.3-5.5-1.3c-0.1,0-0.2,0-0.3,0H3.9L3.9,7.9z M96,88.3c5.3,0,9.6,4.3,9.6,9.6c0,5.3-4.3,9.6-9.6,9.6 c-5.3,0-9.6-4.3-9.6-9.6C86.4,92.6,90.7,88.3,96,88.3L96,88.3z M53.9,88.3c5.3,0,9.6,4.3,9.6,9.6c0,5.3-4.3,9.6-9.6,9.6 c-5.3,0-9.6-4.3-9.6-9.6C44.3,92.6,48.6,88.3,53.9,88.3L53.9,88.3z M33.7,23.7l8.9,33.5h63.1l8.3-33.5H33.7L33.7,23.7z"/>
                                                </svg>

                                            </v-btn>
                                            <transition name="fade">
                                                <div v-if="showAddCart && showTooltipId === item.id"
                                                     :class="[widget.style.roundedCorners ? '' : 'border-radius-0', popupShowAddCart ? 'tooltip__btn-add-cart--popup' : 'tooltip__btn-add-cart']"
                                                     :style="{ background: widget.style.color, 'font-family': widget.style.fontFamily }"
                                                     @click="openCart">
                                                    <span>{{ $t('general.go-to-checkout') }}</span>
                                                </div>
                                            </transition>
                                        </div>
                                    </v-card-actions>
                                </div>
                            </v-card>
                            <div class="popup-variations-wrapper" :class="{ 'active': popup }">
                                <v-card class="position-relative popup-variations"
                                        :class="{ 'active': popup }">
                                    <div class="close-popup">
                                        <button @click="closePopupButton" class="close-popup__btn">&times;
                                        </button>
                                    </div>
                                    <v-card-text class="card-product__wrap-text" style="padding: 0">
                                        <v-card-title class="popup-variations__title text-bold">
                                            {{ popupItem.name }}
                                        </v-card-title>
                                        <v-card-subtitle class="popup-variations__desc"
                                                         :style="{ 'font-family': widget.style.fontFamily, 'margin-top': 0 }">
                                            {{ popupItem.description }}
                                        </v-card-subtitle>
                                    </v-card-text>
                                    <v-img :width="540" :height="290" :max-width="540" :max-height="290" cover
                                           :src="popupItem.image" class="popup-variations__img"
                                           :class="widget.style.roundedCorners ? 'border-radius-20' : 'border-radius-0'"></v-img>
                                    <div class="popup-variations-actions">
                                        <div class="wrap_select" v-for="(variation, ind) in popupVariation"
                                             :key="ind">
                                            <span class="triangle">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                    <path
                                                        d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
                                                </svg>
                                            </span>
                                            <div v-if="popupVariation">
                                                <div class="v-card__title popup-variations__title text-bold">
                                                    {{ variation.title }}
                                                </div>
                                                <select name="select"
                                                        :style="{ 'font-family': widget.style.fontFamily }"
                                                        class="card-product__select"
                                                        :id="'qty_select_popup_' + popupItem.id"
                                                        v-model="selectedVariationItem[ind]"
                                                        @change="subtotal_price_popup_calc(popupItem)">
                                                    <option value="" disabled selected hidden></option>
                                                    <option
                                                        v-for="(variation_item, index) in popupVariationList"
                                                        :key="index"
                                                        :style="variation.id === variation_item.variation_id ? '' : 'display: none'"
                                                        :value="{ value: variation_item.value, price: variation_item.price }"
                                                        class="card-product__option">{{
                                                            variation_item.value
                                                        }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <v-card-actions class="card-actions"
                                                    :class="widget.style.roundedCorners ? '' : 'border-radius-0'">
                                        <div class="order-block">
                                            <div class="qty-block">
                                                <div class="qty-block__interface-row">
                                                    <v-btn small @click="btnMinusPopup(popupItem)"
                                                           :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                                                           class="order-block__btnMinus">
                                                        -
                                                    </v-btn>
                                                    <v-text-field solo small flat
                                                                  :id="'qty_popup_' + popupItem.id"
                                                                  class="qty-block__input order-block__text-field"
                                                                  :value="actuallyValue" type="number"
                                                                  hide-details
                                                                  min="1"
                                                                  @input="checkoutOrder(popupItem)"/>
                                                    <v-btn small @click="btnPlusPopup(popupItem)"
                                                           class="order-block__btnPlus"
                                                           :class="widget.style.roundedCorners ? '' : 'border-radius-0'">
                                                        +
                                                    </v-btn>
                                                </div>
                                                <div
                                                    :style="{ 'font-family': widget.style.fontFamily, 'margin-left': '20px', color: widget.style.color }"
                                                    class="order-block-row__price">
                                                    &#8364; <span :id="'value_price_popup_' + popupItem.id">{{
                                                        actuallyPrice
                                                    }}</span>
                                                </div>
                                            </div>
                                            <v-btn @click="addCartPopup(popupItem)" title="Add to Basket"
                                                   :disabled="!openBtnCart" class="btn_add_cart"
                                                   :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                                                   small
                                                   :style="{ background: widget.style.color, 'font-family': widget.style.fontFamily }">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                                     viewBox="0 0 122.9 107.5" style="margin-right: 3px;">
                                                    <path fill="currentColor"
                                                          d="M3.9,7.9C1.8,7.9,0,6.1,0,3.9C0,1.8,1.8,0,3.9,0h10.2c0.1,0,0.3,0,0.4,0c3.6,0.1,6.8,0.8,9.5,2.5c3,1.9,5.2,4.8,6.4,9.1 c0,0.1,0,0.2,0.1,0.3l1,4H119c2.2,0,3.9,1.8,3.9,3.9c0,0.4-0.1,0.8-0.2,1.2l-10.2,41.1c-0.4,1.8-2,3-3.8,3v0H44.7 c1.4,5.2,2.8,8,4.7,9.3c2.3,1.5,6.3,1.6,13,1.5h0.1v0h45.2c2.2,0,3.9,1.8,3.9,3.9c0,2.2-1.8,3.9-3.9,3.9H62.5v0 c-8.3,0.1-13.4-0.1-17.5-2.8c-4.2-2.8-6.4-7.6-8.6-16.3l0,0L23,13.9c0-0.1,0-0.1-0.1-0.2c-0.6-2.2-1.6-3.7-3-4.5 c-1.4-0.9-3.3-1.3-5.5-1.3c-0.1,0-0.2,0-0.3,0H3.9L3.9,7.9z M96,88.3c5.3,0,9.6,4.3,9.6,9.6c0,5.3-4.3,9.6-9.6,9.6 c-5.3,0-9.6-4.3-9.6-9.6C86.4,92.6,90.7,88.3,96,88.3L96,88.3z M53.9,88.3c5.3,0,9.6,4.3,9.6,9.6c0,5.3-4.3,9.6-9.6,9.6 c-5.3,0-9.6-4.3-9.6-9.6C44.3,92.6,48.6,88.3,53.9,88.3L53.9,88.3z M33.7,23.7l8.9,33.5h63.1l8.3-33.5H33.7L33.7,23.7z"/>
                                                </svg>
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                </v-card>
                            </div>
                        </v-list-item>
                    </div>
                </div>
                <div class="card-wrapper" v-if="show_mode === 2" v-show="!cartIsOpen">
                    <div v-for="item in products" :key="item.id">
                        <v-list-item
                            v-if="activeCategory !== 0 ? item.categories.map(val => val.id).includes(activeCategory) : true"
                            class="card-item mb-3 d-block">
                            <v-card class="card-product d-flex min-width-100"
                                    :class="widget.style.roundedCorners ? '' : 'border-radius-0'">
                                <v-img :max-width="169" :max-height="152" cover
                                       :src="item.image"
                                       class="card-product__img"
                                       :class="widget.style.roundedCorners ? '' : 'border-radius-0'"></v-img>
                                <div class="card-product__data">
                                    <v-card-text class="card-product__wrap-text pa-0">
                                        <v-card-title class="text-h5 card-product__title text-bold pa-0">
                                            {{ item.name }}
                                        </v-card-title>
                                        <v-card-subtitle :style="{ 'font-family': widget.style.fontFamily }"
                                                         v-html="item.description"
                                                         class="card-product__description pa-0">
                                        </v-card-subtitle>
                                    </v-card-text>
                                    <div
                                        :style="{ 'font-family': widget.style.fontFamily, color: widget.style.color }"
                                        class="order-block__price">
                                        &#8364;
                                        <span :id="'value_price_' + item.id">{{
                                                item.price.toFixed(2)
                                            }}</span>
                                    </div>
                                    <v-card-actions class="card-actions"
                                                    :class="widget.style.roundedCorners ? '' : 'border-radius-0'">
                                        <div class="order-block">
                                            <div class="qty-block">
                                                <div class="qty-block__interface"
                                                     v-if="!hasVariation(item)">
                                                    <v-btn small @click="btnMinus(item)"
                                                           :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                                                           class="order-block__btnMinus">
                                                        -
                                                    </v-btn>
                                                    <v-text-field solo small flat :id="'qty_' + item.id"
                                                                  class="qty-block__input order-block__text-field"
                                                                  :value="1"
                                                                  type="number" hide-details min="1"
                                                                  @input="checkoutOrder(item)"/>
                                                    <v-btn small @click="btnPlus(item)"
                                                           :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                                                           class="order-block__btnPlus">
                                                        +
                                                    </v-btn>
                                                </div>
                                            </div>
                                            <v-btn @click="addCart(item)"
                                                   title="Add to Basket"
                                                   class="btn_add_cart"
                                                   small
                                                   :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                                                   :style="{ background: widget.style.color, 'font-family': widget.style.fontFamily, 'position': 'relative' }">
                                                {{ $t('general.add-to-cart') }}
                                            </v-btn>
                                            <transition name="fade">
                                                <div v-if="showAddCart && showTooltipId === item.id"
                                                     :class="[widget.style.roundedCorners ? '' : 'border-radius-0', popupShowAddCart ? 'tooltip__btn-add-cart--popup' : 'tooltip__btn-add-cart']"
                                                     :style="{ background: widget.style.color, 'font-family': widget.style.fontFamily }"
                                                     @click="openCart">
                                                    <span>{{ $t('general.go-to-checkout') }}</span>
                                                </div>
                                            </transition>
                                        </div>
                                    </v-card-actions>
                                </div>
                            </v-card>
                            <div class="popup-variations-wrapper" :class="{ 'active': popup }">
                                <v-card class="position-relative popup-variations"
                                        :class="{ 'active': popup }">
                                    <div class="close-popup">
                                        <button @click="closePopupButton" class="close-popup__btn">&times;
                                        </button>
                                    </div>
                                    <v-card-text class="card-product__wrap-text" style="padding: 0">
                                        <v-card-title class="popup-variations__title text-bold">
                                            {{ popupItem.name }}
                                        </v-card-title>
                                        <v-card-subtitle class="popup-variations__desc"
                                                         :style="{ 'font-family': widget.style.fontFamily, 'margin-top': 0 }">
                                            {{ popupItem.description }}
                                        </v-card-subtitle>
                                    </v-card-text>
                                    <v-img :width="540" :height="290" :max-width="540" :max-height="290" cover
                                           :src="popupItem.image" class="popup-variations__img"
                                           :class="widget.style.roundedCorners ? 'border-radius-20' : 'border-radius-0'"></v-img>
                                    <div class="popup-variations-actions">
                                        <div class="wrap_select" v-for="(variation, ind) in popupVariation"
                                             :key="ind + ind++">
                                            <span class="triangle">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                    <path
                                                        d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
                                                </svg>
                                            </span>
                                            <div v-if="popupVariation">
                                                <div class="v-card__title popup-variations__title text-bold">
                                                    {{ variation.title }}
                                                </div>
                                                <select name="select"
                                                        :style="{ 'font-family': widget.style.fontFamily }"
                                                        class="card-product__select"
                                                        :id="'qty_select_popup_' + popupItem.id"
                                                        v-model="selectedVariationItem[ind - 1]"
                                                        @change="subtotal_price_popup_calc(popupItem)">
                                                    <option value="" disabled selected hidden></option>
                                                    <option
                                                        v-for="(variation_item, index) in popupVariationList"
                                                        :key="index + index++"
                                                        :style="variation.id === variation_item.variation_id ? '' : 'display: none'"
                                                        :value="{ value: variation_item.value, price: variation_item.price }"
                                                        class="card-product__option">{{
                                                            variation_item.value
                                                        }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <v-card-actions
                                        :class="widget.style.roundedCorners ? 'card-actions' : 'border-radius-0'">
                                        <div class="order-block">
                                            <div class="qty-block">
                                                <div class="qty-block__interface-row">
                                                    <v-btn small @click="btnMinusPopup(popupItem)"
                                                           :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                                                           class="order-block__btnMinus">
                                                        -
                                                    </v-btn>
                                                    <v-text-field solo small flat
                                                                  :id="'qty_popup_' + popupItem.id"
                                                                  class="qty-block__input order-block__text-field"
                                                                  :value="actuallyValue" type="number"
                                                                  hide-details
                                                                  min="1"
                                                                  @input="checkoutOrder(popupItem)"/>
                                                    <v-btn small @click="btnPlusPopup(popupItem)"
                                                           :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                                                           class="order-block__btnPlus">
                                                        +
                                                    </v-btn>
                                                </div>
                                                <div
                                                    :style="{ 'font-family': widget.style.fontFamily, 'margin-left': '20px', color: widget.style.color }"
                                                    class="order-block-row__price">
                                                    &#8364; <span :id="'value_price_popup_' + popupItem.id">{{
                                                        actuallyPrice
                                                    }}</span>
                                                </div>
                                            </div>
                                            <v-btn @click="addCartPopup(popupItem)" title="Add to Basket"
                                                   :disabled="!openBtnCart" class="btn_add_cart" small
                                                   :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                                                   :style="{ background: widget.style.color, 'font-family': widget.style.fontFamily }">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                                     viewBox="0 0 122.9 107.5" style="margin-right: 3px;">
                                                    <path fill="currentColor"
                                                          d="M3.9,7.9C1.8,7.9,0,6.1,0,3.9C0,1.8,1.8,0,3.9,0h10.2c0.1,0,0.3,0,0.4,0c3.6,0.1,6.8,0.8,9.5,2.5c3,1.9,5.2,4.8,6.4,9.1 c0,0.1,0,0.2,0.1,0.3l1,4H119c2.2,0,3.9,1.8,3.9,3.9c0,0.4-0.1,0.8-0.2,1.2l-10.2,41.1c-0.4,1.8-2,3-3.8,3v0H44.7 c1.4,5.2,2.8,8,4.7,9.3c2.3,1.5,6.3,1.6,13,1.5h0.1v0h45.2c2.2,0,3.9,1.8,3.9,3.9c0,2.2-1.8,3.9-3.9,3.9H62.5v0 c-8.3,0.1-13.4-0.1-17.5-2.8c-4.2-2.8-6.4-7.6-8.6-16.3l0,0L23,13.9c0-0.1,0-0.1-0.1-0.2c-0.6-2.2-1.6-3.7-3-4.5 c-1.4-0.9-3.3-1.3-5.5-1.3c-0.1,0-0.2,0-0.3,0H3.9L3.9,7.9z M96,88.3c5.3,0,9.6,4.3,9.6,9.6c0,5.3-4.3,9.6-9.6,9.6 c-5.3,0-9.6-4.3-9.6-9.6C86.4,92.6,90.7,88.3,96,88.3L96,88.3z M53.9,88.3c5.3,0,9.6,4.3,9.6,9.6c0,5.3-4.3,9.6-9.6,9.6 c-5.3,0-9.6-4.3-9.6-9.6C44.3,92.6,48.6,88.3,53.9,88.3L53.9,88.3z M33.7,23.7l8.9,33.5h63.1l8.3-33.5H33.7L33.7,23.7z"/>
                                                </svg>
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                </v-card>
                            </div>
                        </v-list-item>
                    </div>
                </div>
                <div class="order_details" v-show="cartIsOpen && cart.length > 0">
                    <div class="date-time" v-if="!order_created">
                        <form-data :widget="widget"
                                   :cart="cart"
                                   :form="form"
                                   :deliveryCost="deliveryCost"
                                   :blocked_btn="blocked_btn" :shop_mode="shop_mode"
                                   :deliveryPrice="updateTotalWithDelivery"
                                   :isPickUp="isPickUp" :isDelivery="isDelivery"
                                   :deliveryButton="deliveryButton"
                                   :pickUpButton="pickUpButton"
                                   :deliveryEnabled="delivery_enabled"
                                   :vendingEnabled="vending_enabled"
                                   :is-vending="isVending"
                                   :vending-button="vendingButton"
                                   :deliveryDesc="deliveryDesc"
                                   :isVatShown="isVatShown" :schedule="schedule"
                                   :create_customer="form.create_customer"
                                   :order_status_message="order_status_message"
                                   @cancel-vending="cancelVending"
                                   @change-date="onChangeDate"
                                   @submit="$emit('submit', cart)"></form-data>
                    </div>
                    <cart :widget="widget" :order_created="order_created"
                          :order_status_message="order_status_message"
                          :blocked_btn="blocked_btn" :cart="cart" :total_price="total_price"
                          :deliveryCost="deliveryCost"
                          :total_price_calc="total_price_calc" :btnMinusCart="btnMinusCart"
                          :btnPlusCart="btnPlusCart"
                          :btnPlusPopupCart="btnPlusPopupCart" :btnMinusPopupCart="btnMinusPopupCart"
                          @remove-from-cart="onRemoveFromCart"></cart>
                </div>
            </div>
            <AccountOrders v-if="accountIsOpen && isAuth"/>
        </div>
    </div>
</template>

<script>
import Cart from "./Cart.vue";
import FormData from "./FormData.vue";
import CustomInput from "./CustomInput.vue";
import AccountOrders from "./AccountOrders.vue";
import debounce from 'lodash/debounce';
import {customerForgotPassword, customerLogin, customerLogout, getCustomerLastOrder} from "@/widget/api";
import {checkAutoCashPayment, checkAutoMolliePayment} from "@/widget/paymentMethods";
import {
    hasVariationsInOriginal,
    parseVariationIdentifiers,
    sumSelectedVariations,
    sumVariations
} from "@/widget/productVariations";
import {mapMutations} from "vuex";

export default {
    components: {Cart, FormData, CustomInput, AccountOrders},
    props: ["widget", "preview", "schedule", "form", "order_created", "order_status_message", "is_login"],
    data: () => ({
        resetLoader: false,
        showLoginForm: true,
        blocked_btn: false,
        shop_mode: 2,
        products: [],
        cart: [],
        total_price: 0,
        total_price_without_delivery: 0,
        selected: 0,
        token: null,
        baseUrl: null,
        headers: null,
        locale: 'en',
        show_mode: 2,
        deliveryCost: false,
        isPickUp: false,
        isVending: false,
        isDelivery: true,
        categories: [],
        activeCategory: 0,
        popup: false,
        popupItem: {},
        popupItemList: [],
        popupVariation: [],
        popupVariationList: [],
        actuallyValue: 1,
        actuallyPrice: 0,
        selectedVariationItem: [],
        variationPrice: [{
            variation: '',
            price: '',
        }],
        variationName: '',
        variationQty: 0,
        delivery_enabled: false,
        vending_enabled: false,
        isVatShown: false,
        cartIsOpen: false,
        accountIsOpen: false,
        variationExistsInCart: false,
        openBtnCart: false,
        deliveryDesc: '',
        showAddCart: false,
        popupShowAddCart: false,
        showTooltipId: null,
        debounceCalcTotal: Function,
        loginPopup: false,
        customer_email: '',
        customer_password: '',
        isAuth: false,
        disabled: false,
        disabledLogout: false,
        repeatOrderErrors: []
    }),
    watch: {
        is_login(val) {
            if (val) {
                this.isAuth = true
            }
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        hasVariation(item) {
            let first = null
            let list = JSON.parse(item.variation_list)
            if (Array.isArray(list) && list.length > 0) {
                first = list[0];
            }
            return first && first.value;
        },
        clear() {
            this.form.name = ""
            this.form.email = ""
            this.form.phone = ""
            this.form.details = ""
            this.form.zipcode = ""
            this.form.address = ""
            this.form.info = ""
            this.form.payment_method = ""
            this.form.date = ""
            this.form.date_object = ""
            this.form.schedule_id = ""
            this.form.schedule_object = ""
            this.form.errors = {}
            this.form.is_vending = false
            this.isVending = false
        },
        repeatLastOrder(event) {
            this.repeatOrderErrors = []
            event.target.disabled = true
            getCustomerLastOrder().then((res) => {
                this.form.name = res.name
                this.form.email = res.email
                this.form.phone = res.phone
                this.form.details = res.details
                this.form.zipcode = res.zipcode
                this.form.address = res.address
                this.form.info = res.info
                this.form.payment_method = res.payment_method
                this.isPickUp = res.delivery_type == 'pick_up'
                this.isDelivery = res.delivery_type == 'delivery'
                if (res.delivery_type === 'pick_up_vending') {
                    this.isVending = true
                    this.form.is_vending = true
                }
                this.form.delivery_enabled = this.isDelivery

                if (this.shop_mode == '1' && this.shop_mode == res.shop_mode) {
                    res.products.forEach((productOrder) => {
                        let productOriginal = this.products.find(fn => fn.id === productOrder.id_product);
                        if (productOriginal) {
                            this.addCartRepeat(productOriginal, productOrder)
                        }
                    })
                    if (this.repeatOrderErrors.length > 0) {
                        this.$emit('show-error-toast', this.repeatOrderErrors.join(', '))
                    }
                    this.openCart()
                }
            }).finally(() => {
                event.target.disabled = false
            })
        },
        setBlocked_btn() {
            this.blocked_btn = true
        },
        setOpen_btn() {
            this.blocked_btn = false
            this.clear()
        },
        init() {
            if (this.widget.settings.products_view_mode) {
                this.show_mode = parseInt(this.widget.settings.products_view_mode, 10);
            }
            this.delivery_enabled = this.widget.settings.delivery_enabled
            this.form.delivery_enabled = this.delivery_enabled
            if (this.widget.settings.is_only_pickup && !this.delivery_enabled) {
                // if is enabled pickup only
                this.delivery_enabled = true
                this.isPickUp = true
                this.isDelivery = false
                this.isVending = false
                if (checkAutoMolliePayment(this.widget.settings)) {
                    this.form.payment_method = 'mollie';
                }
                if (checkAutoCashPayment(this.widget.settings)) {
                    this.form.payment_method = 'cash';
                }
            }
            if (this.widget.settings.vending_enabled) {
                // if is enabled vending
                this.vending_enabled = true
            }
            this.isVatShown = this.widget.settings.is_vat_shown
            this.shop_mode = this.widget.settings.shop_mode
            this.products = this.widget.products
            this.categories = this.widget.categories
            this.popupItemList = this.products.filter(variation => variation.variation_title !== null)
            this.deliveryDesc = this.widget.settings.delivery_desc

            if (this.widget.cart) {
                this.cart = JSON.parse(this.widget.cart.cart_list)
            }
            this.total_price_calc()

            this.locale = this.widget.settings.locale
            this.$i18n.locale = this.widget.settings.locale
        },
        checkoutOrder(item) {
            if (document.getElementById(`qty_${item.id}`).value < 1)
                document.getElementById(`qty_${item.id}`).value = 0
            else if (document.getElementById(`qty_popup_${item.id}`).value < 1)
                document.getElementById(`qty_popup_${item.id}`).value = 0
        },
        openAccount() {
            this.accountIsOpen = true
        },
        closeAccount() {
            this.accountIsOpen = false
        },
        openCart() {
            if (this.cart.length > 0) {
                this.cartIsOpen = true
            } else {
                this.$emit('show-error-toast', this.$t('general.add-item-to-cart'))
            }
        },
        onPasswordForget() {
            this.resetLoader = true;
            customerForgotPassword(this.customer_email).then((response) => {
                if (response.success) {
                    this.$emit('success-forgot-password');
                }
            }).catch((e) => {
                console.log(e);
            }).finally(() => {
                this.resetLoader = false;
            })
        },
        addCart(item) {
            let list = JSON.parse(item.variation_list)
            if (list[0]?.value) {
                this.popupItem = item
                this.popupVariation = JSON.parse(item.variation_title)
                this.popupVariationList = JSON.parse(item.variation_list)
                if (this.actuallyPrice === 0) {
                    this.actuallyPrice = this.popupItem.price
                }
                this.popup = true
                document.getElementsByTagName('html')[0].style.overflow = "hidden";
            } else {
                if (document.getElementById(`qty_${item.id}`).value > 0) {
                    let availability_in_cart = false
                    for (const cart_item of this.cart) {
                        if (cart_item.id_product === item.id) {
                            availability_in_cart = true
                            break
                        }
                    }

                    let arr_select = []
                    arr_select[0] = ''
                    arr_select[1] = ''
                    if (document.getElementById(`qty_select_${item.id}`)) {
                        let data_select = document.getElementById(`qty_select_${item.id}`).value
                        let str = JSON.stringify(data_select)
                        let strParse = JSON.parse(str)
                        arr_select = strParse.split(',');
                    }

                    if (!availability_in_cart) {
                        this.cart.push({
                            'id_product': item.id,
                            'name': item.name,
                            'image': item.image,
                            'price': item.price,
                            'categories': item.categories,
                            'variation_price': '',
                            'variation_name': '',
                            'qty_product': document.getElementById(`qty_${item.id}`).value,
                            'ingredients': item.ingredients,
                        })
                        this.total_price_calc()
                        this.updateTotalWithDelivery()
                    } else if (availability_in_cart) {
                        for (const cart_item of this.cart) {
                            if (cart_item.id_product === item.id) {
                                this.cart.splice(this.cart.indexOf(cart_item), 1, {
                                    'id_product': item.id,
                                    'name': item.name,
                                    'image': item.image,
                                    'price': item.price,
                                    'categories': item.categories,
                                    'variation_price': '',
                                    'variation_name': '',
                                    'qty_product': document.getElementById(`qty_${item.id}`).value,
                                    'ingredients': item.ingredients,
                                })
                                break
                            }
                        }
                        this.total_price_calc()
                        this.updateTotalWithDelivery()
                    }
                }
                this.subtotal_price_calc_cart(item)
                this.popupShowAddCart = false
                this.showTooltipId = item.id
                this.showAddCart = true
                setTimeout(() => this.showAddCart = false, 5000)
            }

            document.getElementsByTagName('html')[0].style.overflow = "auto";
            this.hideHoursExtraProcessingTime()
        },
        addCartPopup(item) {
            if (document.getElementById(`qty_popup_${item.id}`).value > 0) {
                let variation = ''
                let variationPr = 0
                let indexVariation = 0
                let availability_in_cart = false
                for (const cart_item of this.cart) {
                    if (cart_item.id_product === item.id) {
                        availability_in_cart = true
                        break
                    }
                }
                this.variationName = this.selectedVariationItem
                    .filter(item => item.value && item.value.trim().length > 0)
                    .map(item => item.value).join(',')
                this.variationQty = document.getElementById(`qty_popup_${item.id}`).value

                for (const cart_item of this.cart) {
                    if (cart_item.variation_name === this.variationName) {
                        variation = cart_item.variation_name
                        indexVariation = this.cart.indexOf(cart_item)
                        break
                    }
                }

                for (const item of this.variationPrice) {
                    if (item.variation === this.variationName) {
                        variationPr = item.price
                        break
                    }
                }

                if (!availability_in_cart) {
                    this.cart.push({
                        'id_product': item.id,
                        'name': item.name,
                        'image': item.image,
                        'price': item.price,
                        'categories': item.categories,
                        'variation_price': variationPr,
                        'variation_name': this.variationName,
                        'qty_product': document.getElementById(`qty_popup_${item.id}`).value,
                        'ingredients': item.ingredients,
                    })
                    this.total_price_calc()
                    this.updateTotalWithDelivery()
                } else if (availability_in_cart) {
                    if (variation === this.variationName) {
                        this.cart.splice(indexVariation, 1, {
                            'id_product': item.id,
                            'name': item.name,
                            'image': item.image,
                            'price': item.price,
                            'categories': item.categories,
                            'variation_price': variationPr,
                            'variation_name': this.variationName,
                            'qty_product': document.getElementById(`qty_popup_${item.id}`).value,
                            'ingredients': item.ingredients,
                        })
                    } else if (variation !== this.variationName) {
                        this.cart.push({
                            'id_product': item.id,
                            'name': item.name,
                            'image': item.image,
                            'price': item.price,
                            'categories': item.categories,
                            'variation_price': variationPr,
                            'variation_name': this.variationName,
                            'qty_product': document.getElementById(`qty_popup_${item.id}`).value,
                            'ingredients': item.ingredients,
                        })
                    }
                    this.total_price_calc()
                    this.updateTotalWithDelivery()
                }
            }
            this.subtotal_price_calc_popup_cart(item)
            this.showTooltipId = item.id
            this.showAddCart = true
            this.popupShowAddCart = true
            this.popup = false
            setTimeout(() => {
                this.popupShowAddCart = false;
                this.showAddCart = false
            }, 5000)
            document.getElementsByTagName('html')[0].style.overflow = "auto";

            this.hideHoursExtraProcessingTime()
        },
        addCartRepeat(product, productOrder) {
            let variationList = JSON.parse(product.variation_list)
            let variationNames = parseVariationIdentifiers(productOrder.variation_name)
            let isAllFound = hasVariationsInOriginal(variationList, variationNames);
            if (isAllFound) {
                const productPrice = parseFloat(product.price) || 0
                const variationPrice = sumVariations(variationList, variationNames);
                const orderPrice = parseFloat(productOrder.price) || 0
                const orderVariationPrice = parseFloat(productOrder.variation_price) || 0
                this.cart.push({
                    'id_product': product.id,
                    'name': product.name,
                    'image': product.image,
                    'price': productPrice,
                    'categories': product.categories,
                    'ingredients': product.ingredients,
                    'variation_price': variationPrice,
                    'variation_name': productOrder.variation_name,
                    'qty_product': productOrder.qty_product,
                })
                if (productPrice !== orderPrice || variationPrice !== orderVariationPrice) {
                    this.repeatOrderErrors.push(this.$t('general.price-change', {productName: product.name}))
                }
            } else {
                this.repeatOrderErrors.push(this.$t('general.unavailable-items-from-last-order'))
            }

            this.total_price_calc()
            this.updateTotalWithDelivery()

            document.getElementsByTagName('html')[0].style.overflow = "auto";
            this.hideHoursExtraProcessingTime()
        },
        total_price_calc() {
            let total_price_item = 0
            for (let item of this.cart) {
                if (item.variation_price > 0) {
                    total_price_item += Number((item.price + +item.variation_price) * item.qty_product)
                } else {
                    total_price_item += Number(item.price * item.qty_product)
                }
            }
            if (this.deliveryCost) {
                this.total_price_without_delivery = Number(total_price_item)
                this.total_price = (Number(total_price_item) + Number(this.deliveryCost)).toFixed(2)
            } else {
                this.total_price_without_delivery = Number(total_price_item)
                this.total_price = Number(total_price_item).toFixed(2)
            }
        },
        subtotal_price_calc(item) {
            let data_select = 0
            if (document.getElementById(`qty_select_${item.id}`)) {
                data_select = document.getElementById(`qty_select_${item.id}`).value
                data_select = JSON.parse(JSON.stringify(data_select)).split(',')
            }
            let qty = document.getElementById(`qty_${item.id}`).value
            let price = item.price.toFixed(2)
            if (data_select[1]) {
                document.getElementById(`value_price_${item.id}`).textContent = ((Number(price) + Number(data_select[1])) * Number(qty)).toFixed(2)
            } else {
                document.getElementById(`value_price_${item.id}`).textContent = (Number(price) * Number(qty)).toFixed(2)
            }
        },
        subtotal_price_popup_calc(item) {
            let qty = document.getElementById(`qty_popup_${item.id}`).value
            let price = item.price.toFixed(2)
            let selectedVariationPrice = sumSelectedVariations(this.selectedVariationItem)
            this.variationPrice.push({
                variation: this.selectedVariationItem
                    .filter(item => item.value && item.value.trim().length > 0)
                    .map(item => item.value).join(','),
                price: selectedVariationPrice
            })
            this.actuallyPrice = ((Number(price) + Number(selectedVariationPrice)) * Number(qty)).toFixed(2)
            if (this.popupVariation.length === this.selectedVariationItem.length) {
                this.openBtnCart = true
            }
        },
        subtotal_price_calc_cart(item) {
            let data_select = 0
            setTimeout(() => {
                let id = item.id ? item.id : item.id_product
                data_select = document.getElementById(`variation_price_` + id).value ?? 0
                let qty = document.getElementById(`qty_cart${id}`).value
                let price = item.price
                document.getElementById(`cart_value_price_${id}`).textContent = ((Number(price) + Number(data_select)) * Number(qty)).toFixed(2)
            }, 200)
        },
        subtotal_price_calc_popup_cart(item) {
            setTimeout(() => {
                let id = item.id ? item.id : item.id_product
                let qty = document.getElementById(`qty_cart_${id}_${this.variationName}`).value
                let price = item.price
                let variationPr = parseFloat(item.variation_price) > 0 ? item.variation_price : 0

                for (const item of this.variationPrice) {
                    if (item.variation === this.variationName) {
                        variationPr = item.price
                        break
                    }
                }

                document.getElementById(`cart_value_price_${id}_${this.variationName}`).textContent = ((Number(price) + Number(variationPr)) * Number(qty)).toFixed(2)
            }, 200)
        },
        btnPlus(item) {
            document.getElementById(`qty_${item.id}`).value++
            this.subtotal_price_calc(item)
        },
        btnPlusPopup(item) {
            document.getElementById(`qty_popup_${item.id}`).value++
            this.actuallyValue = document.getElementById(`qty_popup_${item.id}`).value
            this.subtotal_price_popup_calc(item)
        },
        btnMinus(item) {
            if (document.getElementById(`qty_${item.id}`).value > 0)
                document.getElementById(`qty_${item.id}`).value--
            this.subtotal_price_calc(item)
        },
        btnMinusPopup(item) {
            if (document.getElementById(`qty_popup_${item.id}`).value > 0)
                document.getElementById(`qty_popup_${item.id}`).value--
            this.actuallyValue = document.getElementById(`qty_popup_${item.id}`).value
            this.subtotal_price_popup_calc(item)
        },
        btnPlusCart(item) {
            document.getElementById(`qty_cart${item.id_product}`).value++

            for (const [index, value] of this.cart.entries()) {
                if (value.id_product === item.id_product) {
                    this.cart[index].qty_product = Number(this.cart[index].qty_product) + 1
                }
            }
            this.total_price_calc()
            this.subtotal_price_calc_cart(item)
            this.updateTotalWithDelivery()
        },
        btnMinusCart(item) {
            let input_val = document.getElementById(`qty_cart${item.id_product}`).value
            if (input_val > 0)
                document.getElementById(`qty_cart${item.id_product}`).value--

            if (Number(document.getElementById(`qty_cart${item.id_product}`).value) === 0)
                this.remove_from_cart(item.id_product)

            if (Number(document.getElementById(`qty_cart${item.id_product}`).value) !== 0) {
                for (const [index, value] of this.cart.entries()) {
                    if (value.id_product === item.id_product) {
                        this.cart[index].qty_product = Number(this.cart[index].qty_product) - 1
                    }
                }
                this.total_price_calc()
                this.subtotal_price_calc_cart(item)
                this.updateTotalWithDelivery()
            }
        },
        btnPlusPopupCart(item) {
            this.variationName = item.variation_name
            document.getElementById(`qty_cart_${item.id_product}_${item.variation_name}`).value++

            for (const [index, value] of this.cart.entries()) {
                if (value.id_product === item.id_product && value.variation_name === item.variation_name) {
                    this.cart[index].qty_product = Number(this.cart[index].qty_product) + 1
                }
            }
            this.total_price_calc()
            this.subtotal_price_calc_popup_cart(item)
            this.updateTotalWithDelivery()
        },
        btnMinusPopupCart(item) {
            this.variationName = item.variation_name
            let input_val = document.getElementById(`qty_cart_${item.id_product}_${item.variation_name}`).value
            if (input_val > 0) {
                document.getElementById(`qty_cart_${item.id_product}_${item.variation_name}`).value--
            }

            if (Number(document.getElementById(`qty_cart_${item.id_product}_${item.variation_name}`).value) === 0)
                this.remove_from_cart(item.id_product)

            if (Number(document.getElementById(`qty_cart_${item.id_product}_${item.variation_name}`).value) !== 0) {
                for (const [index, value] of this.cart.entries()) {
                    if (value.id_product === item.id_product && value.variation_name === item.variation_name) {
                        this.cart[index].qty_product = Number(this.cart[index].qty_product) - 1
                    }
                }
                this.total_price_calc()
                this.subtotal_price_calc_popup_cart(item)
                this.updateTotalWithDelivery()
            }
        },
        btnRow() {
            this.show_mode = 1
            this.cartIsOpen = false
        },
        btnColumn() {
            this.show_mode = 2
            this.cartIsOpen = false
        },
        btnCategory(item) {
            this.activeCategory = item;
            document.querySelectorAll(".category-btns__btn").forEach(el => {
                el.classList.remove("category-btns__btn--active")
                el.removeAttribute('style')
            });
            document.getElementById(item).classList.add("category-btns__btn--active");
            document.getElementById(item).style.background = this.widget.style.color
            this.cartIsOpen = false
        },
        btnCategoryRemove() {
            this.activeCategory = 0;
            document.querySelectorAll(".category-btns__btn").forEach(el => {
                el.classList.remove("category-btns__btn--active")
                el.removeAttribute('style')
            });
            this.cartIsOpen = false
        },
        remove_from_cart(id) {
            for (const [index, value] of this.cart.entries()) {
                if (value.id_product === id) {
                    this.cart.splice(index, 1);
                }
            }
            this.total_price_calc()
            this.hideHoursExtraProcessingTime()
            this.updateTotalWithDelivery()
            this.checkEmptyCart();
        },
        setLocale(locale) {
            this.$i18n.locale = locale
            this.locale = locale
        },
        pickUpButton() {
            this.isPickUp = true
            this.isDelivery = false
            this.isVending = false
            this.form.delivery_enabled = false
            this.form.is_vending = false
            this.blocked_btn = false
            this.deliveryCost = 0
            this.total_price_calc();
        },
        deliveryButton() {
            this.isPickUp = false
            this.isDelivery = true
            this.isVending = false
            this.form.delivery_enabled = true
            this.form.is_vending = false
            this.updateTotalWithDelivery();
        },
        vendingButton() {
            if (!this.isVending) {
                this.isPickUp = false
                this.isDelivery = false
                this.form.delivery_enabled = false
                this.blocked_btn = false
                this.deliveryCost = 0
                this.isVending = true
                this.form.is_vending = true
                this.form.schedule_id = ""
                this.form.schedule_object = ""
                this.form.date_object = ""
            }
        },
        closePopupButton() {
            this.popup = false
            document.getElementsByTagName('html')[0].style.overflow = "auto";
        },
        cancelVending() {
            if (this.isVending) {
                this.isVending = false
                this.form.is_vending = false
                this.form.schedule_id = ""
                this.form.schedule_object = ""
                this.form.date_object = ""
            }
        },
        async calcTotal() {
            try {
                let response = await this.getDelivery(this.form.zipcode);
                if (response.status && response.found_zip) {
                    this.deliveryCost = response.cost;
                    this.blocked_btn = false
                } else {
                    this.deliveryCost = null
                    this.blocked_btn = true
                }
            } catch (e) {
                if (this.form.zipcode && !this.deliveryCost && this.form.zipcode.length > 3) {
                    this.deliveryCost = null
                    this.blocked_btn = true
                    console.dir(e);
                }
            }
            this.total_price_calc();
        },
        async getDelivery(zip) {
            if (document.getElementById("script-widget-base-url") && (zip.length > 3)) {
                const tag_script = document.getElementById("script-widget-base-url");
                let baseUrl = tag_script.dataset.widgetBaseUrl
                let token = tag_script.dataset.token
                let url = `${baseUrl}/api/public/check-zip/${zip}/${token}?cost=${this.total_price_without_delivery}`
                const response = await fetch(url, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        token: token,
                        mode: "cors"
                    }
                });
                return response.json();
            }
        },
        updateTotalWithDelivery() {
            if (this.isDelivery) {
                if (this.form.zipcode && this.form.zipcode.length > 3) {
                    this.blocked_btn = true;
                } else if (!this.form.zipcode || this.form.zipcode.length === 0) {
                    this.blocked_btn = false;
                    this.deliveryCost = 0
                }
                this.debounceCalcTotal();
            }
        },
        getExtraProcessingTime() {
            let extraProcessingTime = []
            this.cart.forEach(product => {
                if (product.categories && product.categories.length > 0) {
                    product.categories.forEach(category => {
                        if (category.extra_processing_time) {
                            extraProcessingTime.push(category.extra_processing_time)
                        }
                    })
                }
            })
            let hideLessThanThisHour = 0
            if (+Math.max(...extraProcessingTime)) {
                let now = new Date();
                let hoursNow = now.getHours()
                hideLessThanThisHour = +hoursNow + +Math.max(...extraProcessingTime) + 1
            }

            return hideLessThanThisHour
        },
        hideHoursExtraProcessingTime() {
            let hideLessThanThisHour = this.getExtraProcessingTime()
            let allHoursElement = document.querySelectorAll('.time_items label div')
            allHoursElement.forEach(item => {
                item.parentNode.style.display = ''
            })
            allHoursElement.forEach(item => {
                let textDiv = item.textContent.trim()
                let hourDiv = textDiv.split(':')[0]
                if (+hourDiv < +hideLessThanThisHour) {
                    item.parentNode.style.display = 'none'
                }
            })
        },
        onRemoveFromCart() {
            this.updateTotalWithDelivery()
            this.checkEmptyCart()
        },
        onCustomerLogin() {
            if (!this.preview) {
                this.disabled = true
                let promise = customerLogin(this.customer_email, this.customer_password);
                promise.then((res) => {
                    if (res['auth-token']) {
                        localStorage.widgetAuthTokenCustomer = res['auth-token']
                        localStorage.customer = res.user.id
                        this.isAuth = true
                        this.loginPopup = false
                        this.customer_email = '';
                        this.customer_password = '';
                        this.setCustomerInfo(res.user);
                    } else {
                        this.$emit('error-login');
                    }
                }).finally(() => {
                    this.disabled = false
                });
            }
        },
        onCustomerLogout() {
            this.disabledLogout = true;
            customerLogout().then(() => {
                this.accountIsOpen = false;
                this.isAuth = false;
                localStorage.removeItem('widgetAuthTokenCustomer');
                localStorage.removeItem('customer');
                this.clearCustomerInfo();
            }).finally(() => {
                this.disabledLogout = false;
            })
        },
        clearCustomerInfo() {
            this.form.name = "";
            this.form.email = "";
            this.form.phone = "";
        },
        setCustomerInfo(customer) {
            this.form.name = customer?.name;
            this.form.email = customer?.email;
            this.form.phone = customer?.phone;
        },
        checkEmptyCart() {
            if (this.cart.length === 0) {
                this.show_mode = 1
                this.cartIsOpen = false
            }
        },
        onChangeDate() {
            this.form.schedule_object = "";
        }
    },
    mounted() {
        // this.isAuth = this.widget.is_auth;
        if (localStorage.widgetAuthTokenCustomer) {
            this.isAuth = true;
        }
        this.setCustomerInfo(this.widget.customer);
        this.init();
        this.debounceCalcTotal = debounce(this.calcTotal, 300);
        if (document.getElementById("script-widget-base-url")) {
            const tag_script = document.getElementById("script-widget-base-url");
            this.baseUrl = tag_script.dataset.widgetBaseUrl
            this.token = tag_script.dataset.token;
        }
    }
};
</script>

<style lang="scss">
button[disabled] {
    background: #cccccc !important;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

    /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.popup-variations-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .1);
    opacity: 0; // Скрываем фон и сам попап
    pointer-events: none; // Запрещаем ему быть целью событий
}

.popup-variations-wrapper.active {
    // При добавлении класса 'active'
    opacity: 1; // Показываем фон и попап
    pointer-events: all; // Возвращаем события
    z-index: 2;
}

.popup-variations {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0); // Центрируем и масштабируем в 0 само окно
    background: #fff;
    width: 590px;
    padding: 25px;
    transition: 0.5s all;
    max-width: 590px;
}

.popup-variations.active {
    // При добавлении класса 'active'
    transform: translate(-50%, -50%) scale(1); // Так же центрируем и плавно увеличиваем
    transition: 0.5s all;
    z-index: 3;
}

.popup-variations-actions {
    margin: 20px 0;
}

.popup-variations__title {
    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 19px;
    color: #232020;
    padding: 0 !important;
    margin-top: 5px;
}

.popup-variations__desc {
    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: 300;
    font-size: 14px !important;
    line-height: 17px;
    color: #8E939A;
    padding: 10px 0;
}

.popup-variations__img {
    display: block;
    width: 540px;
    height: 290px;
    overflow: hidden;
    margin-right: 21px;
}

.close-popup {
    display: flex;
    justify-content: flex-end;
    position: relative;
    left: 15px;
    bottom: 15px;
}

.close-popup__btn {
    font-size: 25px;
    line-height: 1;
}

.widget::-webkit-scrollbar {
    display: none;
}

.widget {
    margin: auto;
    max-width: 100%;
    min-width: 186px;
    /*width: max-content;*/
    height: max-content;
    background: #ffffff;
    box-shadow: none;
    border-radius: 0;
    padding: 25px 21px 0 20px;
    overflow: auto;

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &_form {
        display: flex;
        flex-direction: column;
        margin-top: 25px;

        .widget-row-form {
            margin-bottom: 20px;

            &_negative {
                margin-left: -4px;
                margin-right: -4px;
                margin-bottom: 12px;
            }

            &_flex {
                display: flex;
                flex-wrap: wrap;
            }
        }

        &__details {
            border: 1px solid #cecece;
            border-radius: 5px;
            padding: 9px 0 9px 14px;
            width: 100%;
            font: inherit;
            font-size: 16px;
            box-sizing: border-box;
        }

        &__error {
            color: red;
            font-size: 12px;
            margin-top: 12px;
        }
    }

    &_buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 28px;
        padding: 9px;
        margin-bottom: 12px;
    }

    &_btn {
        padding: 8px 90px;
        border-radius: 10px;
        font-weight: 600;
        color: #000;
        cursor: pointer;
        background: none;
        border: none;
        font-size: 16px;
    }

    &_title {
        color: #232020;
        font-size: 1.17em;
        font-family: 'Inter', sans-serif;
        line-height: 1.5;
        font-weight: bold;
    }

    &-catalog_title {
        color: #232020;
        font-size: 1.5em;
        font-family: 'Inter', sans-serif;
        line-height: 1.5;
        font-weight: 700;
    }

    @media(min-width: 700px) {
        .repeat-last-order-btn {
            padding: 7px 15px;
            border-radius: 10px;
            font-weight: 800;
            font-size: 12px;
            text-transform: uppercase;
            cursor: pointer;

            &:disabled {
                background-color: #fff !important;
                cursor: auto;
                opacity: 0.4;
            }
        }
        .repeat-last-order-svg {
            display: none;
        }
    }

    @media(max-width: 699px) {
        .repeat-last-order-btn {
            display: none;
        }
    }
}

.btn_submit {
    display: flex;
    justify-content: center;
    align-items: center;
}

.without-qty-block {
    margin-left: 15px;
    padding-left: 12px !important;
}

.spinner-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    max-width: 20px;
    max-height: 20px;
    width: 20px;
    height: 20px;
    -webkit-animation-name: spin;
    -webkit-animation-duration: 2000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 2000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 2000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.qty-block__input {
    margin-right: -4px !important;
    margin-left: -4px !important;
    padding: 0 !important;
    background: #FEFEFE;
}

.qty-block__input .v-input__slot {
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding: 0 !important;
    background: #FEFEFE !important;
}

.v-text-field.v-text-field--solo .v-input__control input {
    text-align: center;
    margin-left: -3px;
}

.qty-block__input > .v-input__control {
    min-height: 35px !important;
}

.order-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 100%;
    min-width: 100%;
}

.btn_add_cart {
    box-shadow: none !important;
    font-size: 10px;
    text-transform: none;
    height: 25px;
    padding-left: 20px;
    color: #fff !important;
}

.qty-block__interface {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.qty-block__interface-row {
    display: flex;
    justify-content: center;
    align-items: baseline;
    z-index: 1;
    position: relative;
}

.v-text-field.qty-block__input .v-input__control input::-webkit-outer-spin-button,
.v-text-field.qty-block__input .v-input__control input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.v-text-field.qty-block__input .v-input__control input[type=number] {
    -moz-appearance: textfield;
}

.qty-block__input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-outer-spin-button,
.qty-block__input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.qty-block__input .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
    width: 35px;
    height: 30px;
    font-size: 14px !important;
    margin-left: 5px;
    margin-right: 5px;
}

.min-width-100 {
    min-width: 100%;
}

.card-item {
    display: flex;
    justify-content: center;
    max-width: 100%;
}

.card-product {
    max-width: 394px;
    background: #fff;
    box-shadow: none !important;
    display: flex !important;
    justify-content: center;
    min-height: 100%;
}

.card-product-row {
    width: 100%;
    background: #fff;
    box-shadow: none !important;
    display: flex !important;
    min-height: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px 0 10px 0;

    .card-product-row__img {
        overflow: hidden;
    }
}

@media (max-width: 1150px) {
    .card-product-row {
        width: 75%;
    }
}

.card-product-row-horizontal {
    width: 100%;
    background: #fff;
    box-shadow: none !important;
    display: flex !important;
    min-height: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px 0;
}

.card-product-row__card {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-between;
}

.card-product__data {
    width: 184px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
}

.card-product-row__data {
    min-width: 100px;
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;

    .wrap_select {
        position: relative;
        z-index: 1;
        margin-bottom: 5px;
        margin-top: auto;
        min-width: 150px;
        max-width: 100%;

        .triangle {
            top: 23px;
        }
    }

    .card-product__select {
        position: relative;
        z-index: 12;
        margin-top: 15px;
    }
}

.v-application .card-product.d-block {
    display: flex !important;
}

#btn_cart {
    position: relative;
    z-index: 1;

    #badge_qty {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px;
        position: absolute;
        top: -17px;
        right: -10px;
        z-index: 10;
        width: 21px;
        height: 21px;
        border-radius: 50%;
        font-size: 12px;
        box-shadow: 2px 0 3px #5a5a5a;
    }
}

.order-block__price {
    font-size: 28px;
    font-weight: bold;
    color: #151515;
    margin-bottom: 5px;
    margin-top: auto;
    text-align: end;
}

.order-block-row__price {
    font-size: 28px;
    font-weight: bold;
    color: #151515;
    margin-bottom: 10px;
    margin-top: auto;
}

.card-item .card-product {
    overflow: hidden;
    padding-bottom: 30px;
}

.card-item .card-product .card-product__img {
    display: block;
    width: 189px;
    height: 172px;
    border-radius: 20px !important;
    margin-right: 20px;
}

.card-product-row__img {
    display: block;
    width: 293px;
    height: 185px;
    overflow: hidden;
    margin-right: 21px;
}

.card-item .card-product .card-product__wrap-text {
    padding: 0 !important;

    .card-product__title {
        font-family: 'Inter', 'sans-serif';
        font-style: normal;
        font-weight: 700;
        font-size: 14px !important;
        line-height: 19px;
        color: #232020;
        padding: 0 !important;
        margin-bottom: 20px;
        margin-top: 5px;
    }

    .card-product__description {
        font-family: 'Inter', 'sans-serif';
        font-style: normal;
        font-weight: 300;
        font-size: 12px !important;
        line-height: 17px;
        color: #000;
        padding: 0 !important;
    }
}

.card-product-row__title {
    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 19px;
    color: #232020;
    padding: 0 !important;
    margin-bottom: 20px;
    margin-top: 5px;
}

.card-product-row__description {
    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    color: #8E939A;
    padding: 0 !important;
}

.card-item .card-product .order-block__price,
.order-block-row__price {
    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 19px;
}

.order-block .qty-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.card-product__select {
    position: relative;
    z-index: 12;
    padding: 6px 20px 6px 10px;
    border: 1px solid #F3F1F1;
    margin-top: 5px;
    border-radius: 5px;
    min-width: 100%;
    box-sizing: border-box;
    height: 31px;
    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 17px;
    letter-spacing: -0.015em;
    color: #000000;
    max-width: 100%;
}

.catalog-title-row {
    display: flex;
    justify-content: space-between;
}

.cart {
    position: relative;
    cursor: pointer;
}

.cart__count {
    position: absolute;
    background: #1e5cba;
    color: #fff;
    left: 17px;
    bottom: 20px;
    font-family: Poppins, sans-serif;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21px;
    font-size: 14px;
}

.category-btns {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: #f3f1f1;
    overflow-x: auto;
    padding: 25px 20px;


    &__btn {
        min-width: 132px;
        height: 44px;
        margin-right: 10px;
        background: #FFFFFF;
        border: 1px solid #F3F1F1;
        border-radius: 10px;

        &--active {
            color: #fff !important;
        }

        &--border {
            background: rgb(0, 0, 0, 0);
            min-width: 132px;
            height: 44px;
            margin-right: 10px;
            margin-bottom: 6px;
            border-radius: 10px;
        }
    }

    &::-webkit-scrollbar {
        width: 0;
    }
}

.wrap_select {
    position: relative;
    z-index: 1;
    margin-bottom: 5px;
    margin-top: auto;
}


.wrap_select .triangle {
    color: #f00;
    width: 15px;
    height: 15px;
    display: inline-block;
    position: absolute;
    z-index: 10;
    top: 33px;
    right: 3px;
}

.wrap_select .triangle svg {
    fill: #8E939A;
    pointer-events: none;
}

.card-wrapper {
    margin: 50px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card-wrapper-row {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 100%;
}

.order_details {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
    min-width: 100%;

    .product-cart {
        margin-left: 40px;
        max-width: calc(60% - 40px);

        .card-item {

            .card-product__data {
                width: 100%;
            }

            .card-product {
                width: 100%;
            }

            .card-product .card-product__wrap-text .card-product__title {
                margin-bottom: 0;
            }
        }

        .cart-title {
            color: #232020;
            font-size: 1.17em;
            font-family: 'Inter', sans-serif;
            line-height: 1.5;
            font-weight: 700;
            display: block;
            min-width: 100%;
            margin-bottom: 25px;
        }
    }

    .remove-from-cart {
        width: 20px;
        min-width: 20px;
        height: 108px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 22px;
        color: #ba1e1e;
        transform: translateX(-3px);
        cursor: pointer;
    }

    .card-product__img {
        width: 123px;
        height: 108px;
        max-width: 123px;
        max-height: 108px;
    }
}

.total-price {
    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-top: 40px;
    min-width: 100%;
    margin-left: 7px;

    .total-price__price {
        color: #1E5CBA
    }
}

.mobile-show {
    display: none;
}

.mobile-hide {
    display: block;
}

.desktop-hide {
    display: none;
}

.date-time {
    max-width: 350px;
}

.tooltip__btn-add-cart {
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    display: inline-block;
    padding: 5px 16px;
    position: absolute;
    top: 100px;
    right: 0;
    text-transform: none;
    width: auto;
    white-space: nowrap;
    cursor: pointer;
}

.tooltip__btn-add-cart--popup {
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    display: inline-block;
    padding: 5px 16px;
    position: absolute;
    top: 100px;
    right: 0;
    text-transform: none;
    width: auto;
    white-space: nowrap;
    cursor: pointer;
}

@media only screen and (max-width: 910px) {

    .card-wrapper-row {
        display: block;
    }

    .order_details {
        justify-content: center;

        .date-time {
            order: 2;
            min-width: 100%;
        }

        .mobile-show {
            display: block;

            .widget_btn {
                min-width: 100%;
                font-weight: 100;
            }
        }

        .mobile-hide {
            display: none;
        }

        .desktop-hide {
            display: block;
        }

        .date-time {

            .widget_form,
            .widget_title {
                max-width: 380px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .product-cart {
            margin-left: 0;
            max-width: 100%;
            text-align: center;
            justify-content: center;
        }

        .widget_form {
            .total-price {
                margin-left: 0;
            }
        }
    }

    form .widget_buttons .widget_btn {
        min-width: 100%;
        font-weight: 100;
    }
}

@media only screen and (max-width: 700px) {
    .card-product-row {
        flex-wrap: wrap;
        max-width: 300px;
        min-width: 300px;

        .card-product-row__img {
            min-width: 0;
            max-width: 319px;
            width: 319px;
            margin-right: 0;
        }

        .card-product-row__data {
            min-width: 100%;
            flex-basis: 100%;
            padding-left: 10px;
        }

        .card-product-row__card {
            min-width: 100%;
            padding-right: 10px;
            align-items: center;
            margin-top: 10px;
        }
    }

    .card-item {
        max-width: 100%;
    }

    .card-product {
        flex-direction: column;
        align-items: center;
    }

    .card-item .card-product .card-product__img {
        margin-right: 0;
    }

    .datepicker {
        overflow: auto;
    }

    .popup-variations__img {
        width: 300px !important;
        height: 290px !important;
    }

    .popup-variations {
        width: 350px;
    }

    .without-qty-block {
        margin-left: 0;
    }

    .tooltip__btn-add-cart,
    .tooltip__btn-add-cart--popup {
        top: initial;
        bottom: 40px;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

.order-block-row__price {
    margin-bottom: 8px;
}

.card-product-row__data .wrap_select {
    min-width: 100%;
}

.shop_mode_2 {
    max-width: 365px;
}

.border-radius-0 {
    border-radius: 0 !important;

    button {
        border-radius: 0 !important;
    }
}

.card-actions {
    padding: 0;
    border-radius: 5px;
    overflow: hidden;
}

.border-radius-20 {
    overflow: hidden;
    border-radius: 20px !important;
}

.order-block__text-field {
    height: 25px;
    box-shadow: none;
    text-align: center;
    margin: 0;
    padding: 0;
    background: #f7f7f7
}

.order-block__btnPlus {
    height: 25px;
    background: #F3F1F1;
    color: #8E939A;
    padding: 0 !important;
    box-shadow: none;
    max-width: 20px;
    min-width: 20px !important;
    overflow: hidden;
    border-radius: 0 5px 5px 0;
}

.order-block__btnMinus {
    height: 25px;
    background: #F3F1F1;
    color: #8E939A;
    padding: 0 !important;
    box-shadow: none;
    max-width: 23px;
    min-width: 23px !important;
    overflow: hidden;
    border-radius: 5px 0 0 5px;
}

.shift_btn {
    min-width: 55px;
    right: 5px;

    .v-btn__content {
        left: 3px;
    }
}

/* style reset */
.widget {
    form {
        display: block;
        min-height: auto;

        input {
            margin-bottom: 0;
        }
    }

    button {
        white-space: nowrap;
    }
}

.card-product-row__title,
.category-btns__btn--active,
.category-btns__btn--border,
.category-btns__btn {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 8px;
}

.card-product-row__description {
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-weight: 100;
    color: black !important;
}

.login-popup {
    padding: 20px;
}

.login-popup__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.widget-row-form {
    margin-bottom: 20px;
}

.login-popup-links {
    margin-bottom: 20px;
}

.login-popup-links__forgot {
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    color: #1E5CBA;
}

.login-popup-links__register {
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    color: #232020;
}

.login-popup-btn {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 700;
    min-width: 100%;
    height: 44px;
    margin-right: 10px;
    color: #fff !important;
    background: rgb(30, 92, 186);
}

.remove-from-cart {
    width: 20px;
    min-width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 28px;
    color: #000;
    transform: translateX(-3px);
    cursor: pointer;
}

.be-widget-list-categories {
    max-width: 1200px;

    button {
        margin-bottom: 6px;
    }
}

.be-widget-list-categories__box {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 700px) {
        flex-direction: column;
    }
}

@media only screen and (max-width: 910px) {
    .category-btns__btn--border {
        min-width: 100px;
    }

    #remove {
        margin-bottom: 5px;
        margin-right: 5px;
    }
}

@media only screen and (max-width: 700px) {
    .category-btns {
        padding: 25px 10px;
    }

    #account-btn {
        min-width: 40px;
    }

    #logout-btn {
        min-width: 40px;
    }

    .category-btns__btn {
        margin-right: 5px;
        min-width: 145px;
    }

    .category-btns__btn--border {
        min-width: 80px;
        margin-right: 5px;
        font-size: 14px;
    }

    .category-btns__btn--active {
        min-width: 80px;
        font-size: 14px;
    }
}


.v-dialog {
    transform-origin: center center;
    width: 340px;
}

.profile-logout-container {
    display: flex;
    @media (max-width: 700px) {
        flex-direction: column-reverse;
        button {
            margin-bottom: 5px;
        }
    }
}
</style>
